var render = function render(){
  var _vm$event, _vm$event2, _vm$event3, _vm$event4, _vm$event5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-divider', {
    staticClass: "grey darken4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('div', {
    staticClass: "pa-12px px-md-20px py-md-16px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.isOngoing ? _c('v-chip', _vm._b({
    attrs: {
      "x-small": ""
    }
  }, 'v-chip', Object.assign({}, _vm.chip_secondary2), false), [_vm._v("진행중")]) : _c('v-chip', _vm._b({
    attrs: {
      "x-small": ""
    }
  }, 'v-chip', Object.assign({}, _vm.chip_secondary), false), [_vm._v("종료")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v(_vm._s((_vm$event = _vm.event) === null || _vm$event === void 0 ? void 0 : _vm$event.title))])])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_vm.event.duration ? _c('v-col', [_c('div', {
    staticClass: "txt txt--dark"
  }, [_vm._v(" " + _vm._s((_vm$event2 = _vm.event) !== null && _vm$event2 !== void 0 && _vm$event2.duration[0] ? _vm.$dayjs((_vm$event3 = _vm.event) === null || _vm$event3 === void 0 ? void 0 : _vm$event3.duration[0]).format("YYYY-MM-DD") : "") + " ~ " + _vm._s((_vm$event4 = _vm.event) !== null && _vm$event4 !== void 0 && _vm$event4.duration[1] ? _vm.$dayjs((_vm$event5 = _vm.event) === null || _vm$event5 === void 0 ? void 0 : _vm$event5.duration[1]).format("YYYY-MM-DD") : "") + " ")])]) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "pa-12px px-md-20px py-md-24px min-h-200px"
  }, [_c('div', [_c('div', {
    staticClass: "grey--text font-size-16"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.event.content)
    }
  })])])]), _c('v-divider', {
    staticClass: "border-light"
  }), _c('div', {
    staticClass: "btn-wrap btn-wrap--sm"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    on: {
      "click": function ($event) {
        return _vm.$router.push(`/sub/event/ongoing`);
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("리스트")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }