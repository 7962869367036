<template>
    <div>
        <v-divider class="grey darken4" style="border-width: 2px 0 0" />
        <div class="pa-12px px-md-20px py-md-16px">
            <v-row class="row--xxs" align="center">
                <v-col cols="auto">
                    <v-chip v-if="isOngoing" x-small v-bind="{ ...chip_secondary2 }">진행중</v-chip>
                    <v-chip v-else x-small v-bind="{ ...chip_secondary }">종료</v-chip>
                </v-col>
                <v-col cols="auto">
                    <h3 class="tit tit--xs">{{ event?.title }}</h3>
                </v-col>
            </v-row>
            <v-row class="row--xxs">
                <v-col v-if="event.duration">
                    <div class="txt txt--dark" >
                        {{ event?.duration[0] ? $dayjs(event?.duration[0]).format("YYYY-MM-DD") : "" }} ~ {{ event?.duration[1] ? $dayjs(event?.duration[1]).format("YYYY-MM-DD") : "" }}
                    </div>
                </v-col>
            </v-row>
        </div>

        <div class="pa-12px px-md-20px py-md-24px min-h-200px">
            <div>
                <div class="grey--text font-size-16">
                    <div v-html="event.content"></div>
                </div>
            </div>
        </div>
        <v-divider class="border-light" />

        <div class="btn-wrap btn-wrap--sm">
            <v-row align="center" justify="end">
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_primary }" @click="$router.push(`/sub/event/ongoing`)">리스트</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
import api from "@/api";
import { chip_secondary2, btn_primary, EVENT_STATUS } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import Snb from "@/components/client/list/snb.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        Snb,
    },
    props: {
        status: { type: String, default: EVENT_STATUS.ONGOING.value },
    },
    data: () => ({
        chip_secondary2,
        btn_primary,

        event: {},

        loading: false,
    }),
    computed: {
        isOngoing() {
            return this.status === EVENT_STATUS.ONGOING.value;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },
        async search() {
            this.loading = true;
            try {
                var { event } = await api.v1.shop.events.get({_id: this.$route.params._event}); 

                this.event = event;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
